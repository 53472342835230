<script setup lang="ts">
    import { LazyOrgLanguageDrawer } from '#components';

    const { isInternational, languageName, flag } = useLocales();

    const nuxtApp = useNuxtApp();

    interface LanguageButtonProps {
        theme?: 'navbar' | 'hamburger' | 'footer';
    }
    defineProps<LanguageButtonProps>();

    const openDrawer = () => {
        nuxtApp.$eventEmitter.emit('side-drawer', {
            title: '',
            content: {
                component: LazyOrgLanguageDrawer,
                props: {},
            },
        });
        return false;
    };
</script>
<template>
    <button
        :class="[
            'atm-language-button flex items-center gap-3 text-base transition-colors hover:text-woom-red',
            {
                'inline-flex cursor-pointer flex-row transition-opacity hover:opacity-100': theme === 'navbar',
                'flex border-b border-woom-grey-mid py-3': theme === 'hamburger',
                'mb-auto flex-row-reverse': theme === 'footer',
            },
        ]"
        @click="openDrawer">
        <span
            v-if="!isInternational"
            :title="languageName"
            :class="['flag-icon !w-6', flag, { 'h-[19px] rounded-sm border border-solid border-woom-black bg-cover': theme === 'navbar' }]">
        </span>

        <woom-icon-world
            v-else
            class="h-6 w-6 fill-current" />
        <span
            v-if="theme !== 'navbar'"
            :class="{ 'font-mono text-xs': theme === 'footer', 'text-woom-grey-moon': theme === 'hamburger' }">
            <template v-if="isInternational">Country/Language</template>
            <template v-else>{{ languageName }}</template>
        </span>
    </button>
</template>
